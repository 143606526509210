var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[(true)?_c('div',{staticClass:"container home"},[_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('nav',{staticClass:"breadcrumb is-xlarge",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',{staticClass:"projectName"},[_c('router-link',{attrs:{"to":{
              path: '/' + _vm.$route.params.bot + '/manage',
            }}},[_vm._v(_vm._s(_vm.$route.params.bot)+"\n          ")])],1),_vm._v(" "),_c('li',{staticClass:"is-active",on:{"click":function($event){return _vm.copyGenerator(_vm.$route.params.generator)}}},[_c('b-tooltip',{attrs:{"delay":500,"multilinedNO":"","position":"is-top","type":"is-dark","label":_vm.copied == _vm.$route.params.generator ? 'copied!' : 'copy function name'}},[_c('a',[_vm._v("\n              "+_vm._s(_vm.$route.params.generator)+" Images\n              "),(_vm.generator && _vm.generator.emoji)?_c('span',{staticClass:"emoji"},[_vm._v("   "+_vm._s(_vm.generator.emoji)+" ")]):_vm._e()])])],1)])]),_vm._v(" "),_c('p',[_c('router-link',{staticStyle:{"float":"left","padding-right":"5px"},attrs:{"to":{
          path: '/' + _vm.$route.params.bot + '/endpoints?method=' + 'get',
        },"active-class":"is-active","exact":""}},[_c('b-field',[_c('b-tag',{class:_vm.MethodColors[_vm.generator.method],attrs:{"rounded":"","type":" is-light"}},[_vm._v(_vm._s(_vm.generator.method))])],1)],1)],1),_vm._v(" "),_c('p',{on:{"click":function($event){return _vm.copyGenerator(_vm.generator.path)}}},[_c('b-tooltip',{attrs:{"delay":500,"multilinedNO":"","position":"is-right","type":"is-dark","label":_vm.copied == _vm.generator.path ? 'copied!' : 'copy path'}},[_c('span',{staticStyle:{"opacity":"0.2","margin-right":"10px","margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.generator.path)+" ")])])],1),_vm._v(" "),_c('br',{staticStyle:{"clear":"both"}}),_vm._v(" "),_c('div',{staticClass:"tabs"},[_c('ul',_vm._l((_vm.tabs),function(tab,index){return _c('router-link',{key:index,attrs:{"tag":"li","to":{
            path: '/' + _vm.$route.params.bot + '/images' + tab.path,
          },"active-class":"is-active","exact":""}},[_c('a',[_c('span',{staticClass:"icon is-small"},[_c('i',{class:tab.icon,attrs:{"aria-hidden":"true"}})]),_vm._v(" "),_c('span',[_vm._v(_vm._s(tab.title))])])])}),1)]),_vm._v(" "),(!_vm.loadingBot && _vm.bot && _vm.bot.handle)?_c('div',[_c('router-view',{attrs:{"bot":_vm.bot},on:{"specChange":_vm.specChange}})],1):_vm._e(),_vm._v(" "),_c('imgGenForm',{staticStyle:{"display":"none"}})],1):_vm._e(),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('br')])
}
var staticRenderFns = []

export { render, staticRenderFns }